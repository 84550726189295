
import { mixinCtrls } from "@/mixins/mixinCtrls";
import { mixinValidaciones } from "@/mixins/mixinValidaciones";
import { mixinFormato } from "@/mixins/mixinFormato";
import store_mto from '@/stores/modulos/store_mto.js';


export var mixinMto = {
  mixins: [mixinCtrls, mixinValidaciones, mixinFormato],


  // obtengo entorno, schema y ejecuto acción guardada
  async created() {
    if (!this.$store.state[this.stName]) this.$store.registerModule(this.stName, store_mto);

    this.$store.commit('data2State', { modulo:this.stName, prop:'api', value:this.api });
    this.$store.commit('data2State', { modulo:this.stName, prop:'name', value:this.stName });
    this.$store.commit('data2State', { modulo:this.stName, prop:'componenteTipo', value:this.componenteTipo });
    this.$store.commit('data2State', { modulo:this.stName, prop:'show', value:true });

    //
    if (this.padre) {
      // guardo nombre del Mto actual en su componente padre
      // guardo nombre del padre en el Mto actual
      this.$store.commit('data2State', { modulo:this.stName, prop:'padre', value:this.padre });
      this.$store.commit('data2State', { modulo:this.padre, prop:'hijo', value:this.stName });
    }

    // obtengo schema
    await this.$store.dispatch("get_schema", this.stName);
    console.log("DEV " + this.api + " **********MIXINMTO (created)");


    // configuro según el tipo de componente
    this.ini_tipoComponente();

    // inicializo datos auxiliares del mto
    this.ini_data();

    // inicializar valores controles schema.ctrls si se necesita para el filtro
    this.ini_schemaCtrls();

    //
    this.$watch('disparo',{
      immediate: true,
        handler() {
          this.ini_component();
        }
    });

  },


  //
  computed: {

    // devuelvo si estamos en modo edición en el Mto
    is_edit() {
      return this.estado === "editar" || this.estado === "nuevo"? true : false;
    },

    // devuelvo si el abuelo está en edición
    is_abueloEdit() {
      console.log('is_abueloEdit: ');
      if (!this.padre) return false;

      var abuelo= this.$store.state[this.padre].padre;
      if (!abuelo) return false;

      return this.$store.state[abuelo].estado === "editar" || this.$store.state[abuelo].estado=== "nuevo"? true : false;
    },

    miStPadre() {
      if (!this.padre) return null;
      return this.$store.state[this.padre];
    },

    miSt() {
      if (!this.stName) return null;
      return this.$store.state[this.stName];
    },

    miStHijo() {
      if (!this.$store.state[this.stName]) return null;
      if (!this.$store.state[this.stName].hijo) return null;
      return this.$store.state[this.$store.state[this.stName].hijo];
    },

    M() {
      if (!this.miStPadre) return null;
      if (!this.miStPadre.padre) return null;
      return this.$store.state[this.miStPadre.padre];
    },

    MD() {
      if (!this.padre) return null;
      return this.$store.state[this.padre];
    },

    MX() {
      if (!this.stName) return null;
      return this.$store.state[this.stName];
    },

    miStAbuelo() {
      if (!this.miStPadre) return null;
      if (!this.miStPadre.padre) return null;
      return this.$store.state[this.miStPadre.padre];
    },

    miStNieto() {
      if (!this.miStHijo) return null;
      if (!this.miStHijo.hijo) return null;
      return this.$store.state[this.miStHijo.hijo];
    },

    estado: function() { return this.$store.state[this.stName].estado; },
    record: function() { return this.$store.state[this.stName].record; },
  },


  methods: {

    // OVERRIDE DE FUNCIONES--------------------------------------------
    // inicializa entorno y datos auxiliare
    ini_data(){},
    // inicializa ctrls de filtro
    ini_schemaCtrls(){},
    // gancho antes del ini_component
    before_ini_component() {},
    // entra al saltar el disparoFinder
    ini_component() {
      this.before_ini_component();
      this.btra_Mto({ accion:this.accion });
    },
    //
    ini_tipoComponente() {
    },
    // acciones adicionales después de guardar los datos
    async guardar_fin() {},
    // acciones adicionales despúes de cancelar la edición
    cancelar_fin() {},
    // acciones adicionales despúes de eliminar un registro
    borrar_fin() {},
    // cargo parámetros de get_record en el particular, si los hay
    ver_params() { return null },
    //
    async validate_particular() { return true; },
    // fn que ejecuta al cargar el record del Mto
    record_cargado() {},
    //-----------------------------------------------------------------






    // ---------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------- EVENTOS ---------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------------

    // eventos de la cabercera
    event_Header(evt) {
      console.log("DEV " + this.Entorno.api + " event_Header ********** MIXINMTO", evt);
      if (evt.accion== "cerrar") this.salir({ accion:6 });
    },

    // eventos de los botones de Mto
    event_btra_Mto(evt) {
      console.log("DEV " + this.Entorno.api + " event_btra_Mto ********** MIXINMTO", evt);
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);
      this.btra_Mto(evt);
    },

    // eventos de los botones Extra
    event_btra_Extra(evt) {
      console.log("DEV " + this.Entorno.api + " event_btra_Extra ********** MIXINMTO", evt);
      if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
    },


    async btra_Mto(evt) {
      console.log("DEV " + this.Entorno.api + " btra_Mto ********** MIXINMTO", evt);

      // (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
      const mtoOptions= ['ver', 'nuevo', 'editar', 'borrar', 'guardar', 'cancelar', 'salir'];
      let accion= (/^([0-9])*$/.test(evt.accion))? mtoOptions[evt.accion] : evt.accion;

      if (this.$isFunction(this[accion])) this[accion](evt);

    },


    // ---------------------------------------------------------------------------------------------------------------
    // --------------------------------------- FUNCIONES DE ACCIONES GENERALES ---------------------------------------
    // ---------------------------------------------------------------------------------------------------------------


    //
    async ver() {
      var args= this.ver_params();
      var apiResult= await this.$store.dispatch(this.stName + '/get_record', { id:this.accionRow.id, estado:'ver', args:args });
      console.log('apiResult: ', apiResult);
      // compruebo si record vacio
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        this.$emit("onEvent", { accion: 6 });
        return;
      }

      console.log('************** leido',apiResult.r[0][0])
      // paso record a controles
      await this.record2ctrl(apiResult.r[0][0], this.schema.ctrls, false);

      //
      this.disparoFinder= !this.disparoFinder;

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado();
    },

    // ver sin obtener el registro de la API
    ver_conRecord(apiResult) {
      // compruebo si record vacio
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        this.$emit("onEvent", { accion: 6 });
        return;
      }

      // actualizo records y estado
      this.$store.commit(this.stName + '/get_recordUpdate', { apiResult:apiResult, estado:'ver' });

      // paso record a controles
      this.record2ctrl(apiResult.r[0][0], this.schema.ctrls, false);

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado();
    },


    //
    async nuevo() {
      // compruebo si hay mtos auxialiares en edición
      if (!this.mtos_auxiliares_estado()) return;

      // actualizo estado a 'nuevo'
      this.$store.dispatch(this.stName + '/nuevo');

      this.disparoFinder= !this.disparoFinder;
      await this.record2ctrl({}, this.schema.ctrls, true);

      this.nuevoFin();

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado();
    },

    //after nuevo, datos omision ... etc
    nuevoFin() {
    },

    //
    editar_before(){ return true },
    async editar() {
      // gancho para interceptar el editar en el particular
      // devuelve false aborta editar.
      if (!this.editar_before()) return;

      //
      var args= this.ver_params();
      var apiResult= await this.$store.dispatch(this.stName + '/get_record', { id:this.accionRow.id, estado:'editar', args:args });

      // compruebo si record vacio
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        this.$emit("onEvent", { accion: 6 });
        return;
      }

      // paso record a controles
      await this.record2ctrl(apiResult.r[0][0], this.schema.ctrls, false);

      this.disparoFinder= !this.disparoFinder;

      // fn que ejecuta al cargar el record del Mto
      this.record_cargado();
    },


    // borramos registro actual
    borrar() {
      this.$root.$alert
        .open("¿Desea eliminar el registro?", "confirm")
        .then(r => {
          if (r) this.borrar_registro(this.accionRow.id);
        });
    },

    async borrar_registro(id) {
      console.log('Elimino registro ', id);

      // elimino registro
      var apiResult= await this.$store.dispatch(this.stName + '/borrar');

      // muestro mensaje de error si se no se ha eliminado correctamente
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      // acciones adicionales al terminar de eliminar el registro
      this.borrar_fin(apiResult);

      //
      this.$root.$alert.open("Registro borrado satisfactoriamente", "success");
      this.$emit("onEvent", { accion: 6 });
    },


    // gancho antes de guardar nuevo
    async guardar_before() { return true },
    async guardar() {
      // compruebo si hay mtos auxialiares en edición
      if (!this.mtos_auxiliares_estado()) return false;

      // valido ctrls del schema
      if (!this.validate_fields(this.schema.ctrls)) {
        this.$root.$alert.open("Error de validación de campos", "error");
        return false;
      }

      // valido reglas particulares
      let validate= await this.validate_particular(this.schema.ctrls);
      if (!validate) return false;

      // gancho antes de guardar
      if (!await this.guardar_before()) return;

      // guardo
      switch (this.estado) {
        case "editar":
          return this.guardar_editar();

        case "nuevo":
          return this.guardar_nuevo();
      }

      return false;
    },
    // Gancho al terminar de guardar editar
    guardar_editar_after(){ return true},
    // obtengo los campos modificados y ejecuto grabación
    guardar_editar() {
      // comparo datos del record con los que hay en los controles
      var updateFields= this.compare_record(this.schema.ctrls, this.record);

      // si no hay cambios, hago un ver
      if (Object.keys(updateFields).length <= 0) return this.btra_Mto({ accion:0 });
      // guardo cambios
      let guardarResult=this.guardar_Api({ changes: updateFields });
      if (guardarResult) this.guardar_editar_after(updateFields)
      return guardarResult;
    },


    // obtengo todos los datos del schema y lanzo grabación
    guardar_nuevo_before() { return true },
    guardar_nuevo() {
      if (!this.guardar_nuevo_before()) return;

      // si tenemos un campo relacionado, guardamos el id del abuelo en el schema
      if (this.campo_relacionado) {
        var abuelo= this.$store.state[this.padre].padre;
        if (abuelo) this.schema.ctrls[this.campo_relacionado].value= this.$store.state[abuelo].schema.ctrls['id'].value;
      }

      // paso datos de los controles al record
      var record= this.ctrl2record(this.schema.ctrls, this.record);

      // guardo datos
      return this.guardar_Api({ changes: record });
    },

    before_guardar_Api() {},
    after_guardar_Api() {},

    // muestro mensaje del resultado de la grabación
    async guardar_Api0(data) {
      this.before_guardar_Api(data);
      // guardo datos
      var apiResult= await this.$store.dispatch(this.stName + '/guardar', data);

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return false;
      }
      this.after_guardar_Api(data);
      await this.$root.$alert.open("Registro guardado correctamente!", "success", 1800);

      // si es un nuevo, actualizo id
      if (this.estado== 'nuevo') this.accionRow.id= apiResult.r[0][0].id;

      // recargo datos
      await this.ver_conRecord(apiResult);

      // acciones adicionales al terminar de guardar
      await this.guardar_fin(apiResult);
      return true;
    },


    // muestro mensaje del resultado de la grabación
    async guardar_Api(data) {
      this.before_guardar_Api(data);
      // guardo datos
      var apiResult= await this.$store.dispatch(this.stName + '/guardar', data);

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return false;
      }
      this.after_guardar_Api(data);

      // si es un nuevo, actualizo id
      if (this.estado== 'nuevo') this.record.id=this.accionRow.id= apiResult.r[0][0].id;

      // acciones adicionales al terminar de guardar
      await this.guardar_fin(apiResult);

      await this.$root.$alert.open("Registro guardado correctamente!", "success", 1800);

      // recargo datos
      await this.ver_conRecord(apiResult);

      return true;
    },



    // cancelo la edición del registro
    async cancelar() {
      // compruebo si hay mtos auxialiares en edición
      if (!this.mtos_auxiliares_estado()) return;

      switch (this.estado) {
        case "editar":
          this.cancelar_editar();
          break;

        case "nuevo":
          this.cancelar_nuevo();
          break;
      }

      // acciones adicionales al cancelar la edición
      this.cancelar_fin();
    },

    //
    async cancelar_editar() {
      // compruebo si hay cambios en el Mto
      if (Object.keys(this.compare_record(this.schema.ctrls, this.record)).length> 0) {
        this.cancelar_pregunta();
        return;
      }

      // NO hay cambios. Recargo datos
      this.btra_Mto({ accion:0 });
    },

    //
    async cancelar_pregunta() {
      await this.$root.$alert.open("¿ CANCELAR los cambios realizados ?","confirm",null,["NO","SI"])
        .then(r => {
          if (r) this.btra_Mto({ accion:0 });
        });
    },

    //
    cancelar_nuevo() {
      this.$store.commit('data2State', { modulo:this.stName, prop:'estado', value:'ver'});
      this.$emit('onEvent', { accion:5 });
    },


    //
    mtos_auxiliares_estado() {
      console.log("DEV " + this.api + " ********************* mtos_auxiliares_estado");

      //
      if (!this.mtos_auxiliares) return true;

      var estado;
      for (var x=0; x< this.mtos_auxiliares.length; x++) {
        if (!this.$store.state[this.mtos_auxiliares[x].store]) break;

        estado= this.$store.state[this.mtos_auxiliares[x].store].estado;
        if (estado== 'editar' || estado== 'nuevo') {
          this.$root.$alert.open(this.mtos_auxiliares[x].msg, "error");
          return false;
        }

      }

      return true;
    },


    //
    salir(evt)
    {
      // compruebo si hay mtos auxialiares en edición
      if (!this.mtos_auxiliares_estado()) return;

      // compruebo si el mto está en edición
      if (this.estado== "nuevo") return this.salir_error();

      var hijo= this.$store.state[this.stName].hijo;
      if (hijo) {
        if (this.$store.state[hijo].hijoChanges) {
          this.salir_error();
          return;
        }
      }

      if (this.estado== "ver" || Object.keys(this.compare_record(this.schema.ctrls, this.record)).length <= 0) {
        this.$emit('onEvent', evt);
        return;
      }

      this.salir_error();
    },

    //
    salir_error() {
      this.$root.$alert.open("Debe GUARDAR/CANCELAR antes de salir", "info");
    },


  },


};
