
export var mixinValidaciones = {
  methods: {
    // validación de datos de controles
    // recorro el arrau de controles recibido y llamo a la fn 'check_validate'
    // para comprobar si el valor del campo es válido o no
    validate_fields(ctrls) {
      for (const key in ctrls) {
        if (!ctrls[key].validate) continue;
        if (!this.check_validate(ctrls[key])) return false;
      }

      return true;
    },

    // compruebo si el valor del campo es válido o no
    check_validate(ctrl) {
      // valido el value del componente recibido, si tiene la propiedad validate definida
      if (!ctrl.validate) return;

      // borro los errores del control
      ctrl.error = false;
      ctrl["error-messages"] = "";

      // recorro todas las opciones para validar y voy comprobándolas
      for (const key in ctrl.validate) {
        switch (key) {
          case "min":
            // nº mínimo de carácteres
            if (ctrl.value.length < ctrl.validate[key]) {
              ctrl.error = true;
              ctrl["error-messages"] =
                "Mínimo " + ctrl.validate[key] + " carácteres";
              return false;
            }
            break;

          case "max":
            // nº máximo de carácteres
            if (ctrl.value.length > ctrl.validate[key]) {
              ctrl.error = true;
              ctrl["error-messages"] =
                "Máximo " + ctrl.validate[key] + " carácteres";
              return false;
            }
            break;

          case "required":
            // datos requiridos
            if (ctrl.value.length <= 0) {
              ctrl.error = true;
              ctrl["error-messages"] = "Datos requeridos";
              return false;
            }
            break;

          case "fn":
            // función particular
            var r = this.$isFunction(this[ctrl.validate.fn])
              ? this[ctrl.validate.fn](ctrl)
              : true;

            if (r) break;
            ctrl.error = true;
            ctrl["error-messages"] =
              "Error en la función de validación definida";
            return false;
        }
      }

      return true;
    },
    
  }
};
