export var mixinCalculos = {
  data() {
    return {
      //
      pcalculos: {
        servicios: {
          Aimp: []
        },

        iva_gral: {
          iva: "21"
        },

        iva_desglose: {
          Aimp: []
        },

        sumas: {
          T_bases: 0,
          T_ivas: 0,
          T_suplidos: 0
        },

        totales: {
          T_pnf: 0,
          T_sup_pd: 0,
          T_imp_cia: 0,
          T_importe: 0
        }
      },

      Aclaves: [
        { grupo: "Servicio", claves: ["XJ", "FR", "CD"] },
        { grupo: "Nicho", claves: ["YA"] },
        { grupo: "Incineracion", claves: ["YJ"] },
        { grupo: "Tanatorio", claves: ["YC"] },
        { grupo: "Lapida", claves: ["YB"] },
        { grupo: "Recog. Jud.", claves: ["YW"] },
        { grupo: "Traslados", claves: ["XP", "YH", "XY"] }
      ]
    };
  },

  methods: {
    //
    calc_general(records) {
      this.calc_servicios(records);
      this.calc_iva_desglose(records);
      this.calc_sumas(records);
      this.calc_totales(records);
      this.calc_iva_gral();

      return this.pcalculos;
    },

    // ******************************************************************************************** cálculo servicios
    calc_servicios(records) {
      this.reset_servicios();
      this.servicios_acumulados(records);

      return this.pcalculos.servicios;
    },

    // reseteo datos de servicios
    reset_servicios() {
      this.pcalculos.servicios.Aimp = [];

      for (let x = 0; x < 3; x++) {
        this.pcalculos.servicios.Aimp[x] = [0, 0, 0, 0, 0, 0, 0];
      }
    },

    servicios_acumulados(records) {
      if (!records) return;

      var iva;
      var imp;

      for (const key in records) {
        const item = records[key];

        imp = Number(item.imp);
        iva = Number(item.iva);
        imp += imp * (iva / 100);

        this.servicios_acumulados_continue(imp, item["clave"]);
      }
    },

    servicios_acumulados_continue(imp, clave) {
      // acumulo según la clave
      for (let i = 0; i < this.Aclaves.length; i++) {
        if (this.Aclaves[i].claves.indexOf(clave) > -1) {
          this.pcalculos.servicios.Aimp[1][i] += Number(imp);
          if (i < 5)
            this.pcalculos.servicios.Aimp[2][i] =
              this.pcalculos.servicios.Aimp[0][i] -
              this.pcalculos.servicios.Aimp[1][i];
        }
      }
    },

    // ******************************************************************************************** cálculo iva desglose
    calc_iva_desglose(records) {
      this.reset_iva_desglose();
      this.iva_desglose_acumulados(records);

      return this.pcalculos.iva_desglose;
    },

    //
    reset_iva_desglose() {
      this.pcalculos.iva_desglose.Aimp = [];

      for (let x = 0; x < 5; x++) {
        this.pcalculos.iva_desglose.Aimp[x] = [0, 0, 0];
      }
    },

    iva_desglose_acumulados(records) {
      if (!records) return;

      var iva;
      var imp;

      for (const key in records) {
        const item = records[key];

        imp = Number(item.imp);
        iva = Number(item.iva);

        this.iva_desglose_acumulados_continue(imp, iva);
      }
    },

    //
    iva_desglose_acumulados_continue(imp, iva) {
      // acumulo porcentaje de iva
      var tmpbase;
      for (let i = 0; i < 5; i++) {
        if (this.pcalculos.iva_desglose.Aimp[i][0] === iva) {
          tmpbase = Number(this.pcalculos.iva_desglose.Aimp[i][2]);
          tmpbase = tmpbase + imp;
          this.pcalculos.iva_desglose.Aimp[i][2] = this.round(tmpbase);
          return;
        }
      }

      // nuevo porcentaje de iva
      for (let i = 0; i < 5; i++) {
        if (this.pcalculos.iva_desglose.Aimp[i][2] === 0) {
          this.pcalculos.iva_desglose.Aimp[i][2] = imp;
          this.pcalculos.iva_desglose.Aimp[i][0] = iva;
          if (i > 1) this.reordena_iva(i);
          return;
        }
      }
    },

    // reordeno porcentaje iva de mayo a menor
    reordena_iva(_i) {
      var bas = 0;
      var iva = 0;

      for (let i = 1; i <= _i; i++) {
        for (let j = 1; j <= _i; j++) {
          if (i == j) continue;
          if (
            this.pcalculos.iva_desglose.Aimp[i][0] >
            this.pcalculos.iva_desglose.Aimp[j][0]
          ) {
            bas = this.pcalculos.iva_desglose.Aimp[i][2];
            iva = this.pcalculos.iva_desglose.Aimp[i][0];

            this.pcalculos.iva_desglose.Aimp[
              i
            ][2] = this.pcalculos.iva_desglose.Aimp[j][2];
            this.pcalculos.iva_desglose.Aimp[
              i
            ][0] = this.pcalculos.iva_desglose.Aimp[j][0];

            this.pcalculos.iva_desglose.Aimp[j][2] = bas;
            this.pcalculos.iva_desglose.Aimp[j][0] = iva;
          }
        }
      }
    },

    // ******************************************************************************************** cálculo iva desglose
    calc_totales(records) {
      this.reset_totales();
      this.totales_acumulados(records);

      return this.pcalculos.totales;
    },

    //
    reset_totales() {
      this.pcalculos.totales.T_pnf = this.pcalculos.totales.T_sup_pd = this.pcalculos.totales.T_imp_cia = this.pcalculos.totales.T_importe = 0;
    },

    //
    totales_acumulados(records) {
      if (!records) return;

      var iva10 = ["12", "21", "52"];
      var iva_item;

      for (const key in records) {
        const item = records[key];

        var imp = Number(item.imp);

        // pnfsuma
        // calculo el importe de la funeraria (todas las lineas sp)
        if (Number(item.sp) === 0)
          this.pcalculos.totales.T_pnf +=
            imp + this.round((Number(item.iva) * imp) / 100);

        // imp_cia (Iva General)
        iva_item = 21;

        // si son flores al 10
        if (iva10.indexOf(item.prod_id) >= 0 && Number(item.iva) !== 3)
          iva_item = 10;

        // si es de canarias prevalece al 3
        if (item.iva === 3) iva_item = Number(item.iva);

        // si es pago directo y suplido no lleva iva
        if (Number(item.sp) === 1 && item.sup === "S") {
          iva_item = 0;
          this.pcalculos.totales.T_sup_pd += imp;
        }

        // importe compañía
        this.pcalculos.totales.T_imp_cia +=
          imp + this.round((Number(iva_item) * imp) / 100);
        this.pcalculos.totales.T_imp_cia = this.round(
          Number(this.pcalculos.totales.T_imp_cia)
        );

        // importe Total
        this.pcalculos.totales.T_importe =
          this.pcalculos.sumas.T_bases +
          this.pcalculos.sumas.T_ivas +
          this.pcalculos.sumas.T_suplidos;
      }
    },

    // ******************************************************************************************** cálculo iva general
    calc_iva_gral() {
      return this.pcalculos.iva_gral;
    },

    // ******************************************************************************************** cálculo sumas
    calc_sumas(records) {
      this.reset_sumas();
      this.sumas_acumulados(records);

      return this.pcalculos.sumas;
    },

    //
    reset_sumas() {
      this.pcalculos.sumas.T_bases = this.pcalculos.sumas.T_ivas = this.pcalculos.sumas.T_suplidos = 0;
    },

    //
    sumas_acumulados(records) {
      // si no hay datos en el array de iva desglose, calculo esos datos
      if (!records) return;
      if (this.pcalculos.iva_desglose.Aimp.length <= 0)
        this.calc_iva_desglose(records);

      var base;
      var iva;
      var ivapor;

      for (let i = 0; i < 5; i++) {
        ivapor = this.pcalculos.iva_desglose.Aimp[i][0];
        iva = this.pcalculos.iva_desglose.Aimp[i][1];
        base = this.pcalculos.iva_desglose.Aimp[i][2];

        if (base !== 0) {
          iva = Math.abs(base) * ivapor;
          iva = this.round(iva / 100);

          if (base < 0) iva = iva * -1;
          this.pcalculos.sumas.T_ivas += iva;
        }

        if (iva === 0) {
          this.pcalculos.sumas.T_suplidos += base;
        } else {
          this.pcalculos.sumas.T_bases += base;
        }
      }
    },

    round0(n, d = 100) {
      n = Number((n * d).toFixed(2));
      n = Math.round(n);
      n = n / 100;
      return n;
    },

    round(n, d=2) {                
      let r=Number(Math.round(n*Math.pow(10,d))/Math.pow(10,d).toFixed(d));
      console.log('*round',n,r);
      return r;
    },    

    //
    /* calc_capitales(schema)
        {           

            console.log('CALC_CAPITALES (SCHEMA): ', schema);
            var claves= this.item_calculos.claves;

            claves.T_capital= 0;
            claves.T_servicio= 0;
                            
            // guardo capitales en el array general
            claves.Aimp[0][0]= Number(schema.servpm);
            claves.Aimp[1][0]= Number(schema.servnicho);
            claves.Aimp[2][0]= Number(schema.servincinera);
            claves.Aimp[3][0]= Number(schema.servlapida);
            claves.Aimp[4][0]= Number(schema.servtana);

            // SUMA DE CAPITALES
            claves.T_capital= (claves.Aimp[0][0]+claves.Aimp[1][0]+claves.Aimp[2][0]+claves.Aimp[3][0]+claves.Aimp[4][0]).toFixed(2);

            // SUMA DE SERVICIO no incluye recog judicial ni traslado
            claves.Aimp[6][0]= 0;

            // SERVICIO
            claves.T_servicio= (claves.Aimp[6][0]).toFixed(2);

            // DIFERENCIA
            claves.diferencia= (claves.T_capital - claves.Aimp[6][0]).toFixed(2);

            // COMPLEMENTO
            claves.complemento= schema.servcomplemento;		    
        }, */
  }
};
