<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="pres_funes_M">
    <!-- Base Mto -->
    <base_M
      v-if="schema"
      :schema="schema"
      :Entorno="Entorno"
      :accion="accion"
      @onEvent="event_capture"
    >
      <!-- Slot con los controles del Mto -->
      <template v-slot:ctrls="{}">
        <div style="display:flex">
          <div class="columna">
            <div class="cab">DATOS PRESUPUESTO</div>
            <v-sheet width="500px" height="400px" v-bind="$cfg.styles.marco">
              <!-- <ctrlfinder :schema="schema.ctrls.municipio" :edicion="edicion"/> -->
              <div style="display:flex">
                <!-- <v-select
                          style="flex: 0 0 10%"
                          v-bind="$select"
                          v-model="schema.ctrls.ano.value"
                          :label="schema.ctrls.ano.label"
                          :items="ans"
                          :disabled="!edicion"
                          item-value="id"
                          item-text="name"
                        >
                        </v-select> -->

                <v-text-field
                  v-bind="$input"
                  v-model="schema_M.ctrls.control.value"
                  :label="schema_M.ctrls.control.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.tipo_ope.value"
                  :label="schema.ctrls.tipo_ope.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.suplido.value"
                  :label="schema.ctrls.suplido.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <!-- <v-select
                          v-bind="$select"
                          v-model="schema.ctrls.id_presciac.value"
                          :label="schema.ctrls.id_presciac.label"
                          :items="get_prestacion"
                          :disabled="!edicion"
                          item-value="id"
                          item-text="name"
                        >
                        </v-select> -->
                <!-- <v-checkbox
                          v-bind="$checkbox"
                          v-model="schema.ctrls.preferente.value"
                          label="Pref"
                          :disabled="!edicion"
                        >
                        </v-checkbox> -->
              </div>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.fra_doc.value"
                  :label="schema.ctrls.fra_doc.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <compfecha
                  style="flex: 0 0 30%"
                  :schema="schema.ctrls.fra_fh"
                  :edicion="edicion"
                >
                </compfecha>
              </div>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.pre_doc.value"
                  :label="schema.ctrls.pre_doc.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <compfecha
                  style="flex: 0 0 30%"
                  :schema="schema.ctrls.pre_fh"
                  :edicion="edicion"
                >
                </compfecha>
              </div>

              <div style="display:flex">
                <v-select
                  style="flex: 0 0 10%"
                  v-bind="$select"
                  v-model="schema.ctrls.rel_tp.value"
                  :label="schema.ctrls.rel_tp.label"
                  :items="get_tipoRelacion"
                  :disabled="!edicion"
                  item-value="id"
                  item-text="name"
                >
                </v-select>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.relacion.value"
                  :label="schema.ctrls.relacion.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-checkbox
                  v-bind="$checkbox"
                  v-model="schema.ctrls.tipo_carabon.value"
                  :label="schema.ctrls.tipo_carabon.label"
                  :disabled="!edicion"
                >
                </v-checkbox>
              </div>

              <div style="display:flex">
                <v-icon :color="get_colorLock">mdi-lock </v-icon>

                <v-icon :color="get_colorAccept"
                  >mdi-shield-check-outline
                </v-icon>
              </div>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.cta_id.value"
                :label="schema.ctrls.cta_id.label"
                :disabled="!edicion"
              >
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="schema_M.ctrls.fallename.value"
                :label="schema_M.ctrls.fallename.label"
                readonly
              >
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.descrip.value"
                :label="schema.ctrls.descrip.label"
                :disabled="!edicion"
              >
              </v-text-field>

              <v-textarea
                class="textarea"
                v-model="schema.ctrls.obs_fra.value"
                :edicion="edicion"
                rows="2"
                rounded
                filled
                no-resize
              >
              </v-textarea>

              <!-- <v-text-field
                        v-bind="$input"
                        v-model="schema.ctrls.descrip.value"
                        :label="schema.ctrls.descrip.label"
                        :disabled="!edicion"
                      >
                      </v-text-field> -->
              <!-- <ctrlfinder :schema="schema.ctrls.cia_id"    :edicion="edicion"/> -->

              <!-- <div style="display:flex">
                        <v-text-field
                          v-bind="$input"
                          v-model="schema.ctrls.paxfun.value"
                          :label="schema.ctrls.paxfun.label"
                          :disabled="!edicion"
                        >
                        </v-text-field>

                        <compfecha
                          style="width:150px"
                          :schema="schema.ctrls.fhrev"
                          :edicion="edicion"
                        >
                        </compfecha>
                      </div> -->
              <!-- <div style="display:flex">
                        <v-input_n
                          v-bind="$input"
                          v-model="schema.ctrls.imp_ant.value"
                          :label="schema.ctrls.imp_ant.label"
                          :disabled="!edicion"
                        >
                        </v-input_n>

                        <v-input_n
                          v-bind="$input"
                          v-model="schema.ctrls.imp.value"
                          :label="schema.ctrls.imp.label"
                          :disabled="!edicion"
                        >
                        </v-input_n>
                      </div> -->
            </v-sheet>

            <div style="display:flex">
              <pres_claves :pdat="pservicios" :edicion="edicion" />

              <div class="columna">
                <pres_sumas :pdat="psumas" :edicion="edicion" />
                <pres_iva_gral :pdat="piva_gral" :edicion="edicion" />
              </div>

              <div class="columna">
                <pres_iva_desglose :pdat="piva_desglose" :edicion="edicion">
                </pres_iva_desglose>
              </div>
            </div>
            <!-- fin de columna principal izquierda-->
          </div>

          <div class="columna">
            <pres_totales
              :pdat="ptotales"
              :imp_ant="schema.ctrls.imp_ant.value"
              :edicion="edicion"
            />

            <v-sheet v-bind="$cfg.styles.marco">
              <!-- DETALLE -->
              <pres_exp_detalle
                ref="presup_detalle"
                :id="id"
                :accion="accion"
                :edicion="edicion"
                :Entorno_F="Entorno_presup_detalle_F"
                @onEvent="event_capture"
              >
              </pres_exp_detalle>
            </v-sheet>
          </div>
        </div>
      </template>
    </base_M>
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import { mixinMD } from "@/mixins/mixinMD.js";
import { mixinCalculos } from "@/mixins/mixinCalculos.js";
import plugs from "@/common/general_plugs";
const base_M = () => plugs.groute("base_M.vue", "base");
const compfecha = () => plugs.groute("compfecha.vue", "comp");
/*const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp"); */
const pres_exp_detalle = () => plugs.groute("pres_exp_detalle.vue", "comp");
const pres_claves = () => plugs.groute("pres_claves.vue", "comp");
const pres_sumas = () => plugs.groute("pres_sumas.vue", "comp");
const pres_iva_gral = () => plugs.groute("pres_iva_gral.vue", "comp");
const pres_iva_desglose = () => plugs.groute("pres_iva_desglose.vue", "comp");
const pres_totales = () => plugs.groute("pres_totales_horizontal.vue", "comp");

export default {
  mixins: [mixinMto, mixinMD, mixinCalculos],
  components: {
    base_M,
    compfecha,
    pres_exp_detalle,
    pres_claves,
    pres_sumas,
    pres_iva_gral,
    pres_iva_desglose,
    pres_totales
  },

  props: {
    schema_M: { type: Object, default: null },
    id_accion: { type: Object, default: null },
    Entorno: {
      type: Object,
      default: () =>
        function() {
          return JSON.parse(JSON.stringify(this.$cfg.base.M));
        }
    }
  },

  data() {
    return {
      schema: null,
      id: this.id_accion.id,
      accion: this.id_accion.accion,
      edicion: false,

      ans: this.get_ans(),
      //
      producto: null,
      fila_copiada: null,
      pservicios: null,
      psumas: null,
      piva_gral: null,
      piva_desglose: null,
      ptotales: null,

      lineas_actualizadas: false,
      records_detalle: null,
      schema_detalle: null,

      Entorno_presup_detalle_F: this.get_entorno_presup_detalle_F()
    };
  },

  computed: {
    // array de Tipo de Relación
    get_tipoRelacion() {
      return [
        { id: "0", name: "*****" },
        { id: "1", name: "Parcial" },
        { id: "2", name: "Nula" },
        { id: "3", name: "Total" }
      ];
    },

    //
    get_colorLock() {
      switch (this.schema.ctrls.bloqueada.value) {
        case "0":
          return "green";

        case "1":
          return "red";

        default:
          return "";
      }
    },

    //
    get_colorAccept() {
      switch (this.schema.ctrls.aceptada.value) {
        case "0":
          return "yellow";

        case "1":
          return "green";

        case "2":
          return "green";

        default:
          return "";
      }
    }
  },

  methods: {
    // gestor de eventos
    event_capture(evt, data) {
      console.log("*** onEvent pres_funes_M. Accion: ", evt, " ***");

      if (this.$isFunction(this[evt.accion])) {
        this[evt.accion](evt, data);
        return;
      }
      this.acciones_Mto(evt);
    },

    // configuro entorno Mto particular
    get_entorno_Mto() {
      this.set_btra();
      this.set_btraExtra();

      if (!this.Entorno.header.titulo)
        this.Entorno.header.titulo = "Presupuestos / Facturas";
      this.Entorno.api = "pres_exp_M";
      this.Entorno.header.style += ";min-width:60rem";
    },

    //
    set_btraExtra() {},

    // configuro entorno Finder Detalle de presupuestos
    get_entorno_presup_detalle_F() {
      var F = JSON.parse(JSON.stringify(this.$cfg.base.D));
      F.btra = "detalle";
      return F;
    },

    //
    get_ans() {
      let ans = [];
      let i = 0;
      let a = new Date().getFullYear() - 2;
      while (i < 3) {
        ans.push({ id: (a + i).toString(), name: a + i });
        i++;
      }

      return ans;
    },

    // evento recibido del detalle indicando que las lineas han sido cargadas
    detalle_cargado(data) {
      // guardo records y schema detalle
      this.records_detalle = data.records;
      this.schema_detalle = data.schema;

      // indico que no hay cambios en las lineas de detalle
      this.lineas_actualizadas = false;

      // cargo datos de componentes del record de cabecera
      this.mount_presupuestos(data.records);
    },

    // evento recibido del detalle indicando que alguna linea ha sido modificada
    detalle_modificado(data) {
      // guardo records y schema detalle
      this.records_detalle = data.records;
      this.schema_detalle = data.schema;

      // indico que hemos modificado una linea del detalle
      this.lineas_actualizadas = true;

      // recalculo datos de componentes
      var pcalculos = this.calc_general(data.records);

      this.pservicios = pcalculos.servicios;
      this.piva_gral = pcalculos.iva_gral;
      this.piva_desglose = pcalculos.iva_desglose;
      this.psumas = pcalculos.sumas;
      this.ptotales = pcalculos.totales;
    },

    //
    mount_presupuestos(records_detalle) {
      console.log(
        "MONTO PRESUPUESTOS MANUALES ----------------------------------------------------------------------------"
      );

      // servicios
      this.pservicios = this.calc_servicios(records_detalle);

      // iva general
      this.piva_gral = {
        iva: this.schema.ctrls.iva_general.value
      };

      // iva desglose
      this.piva_desglose = {
        Aimp: [
          [this.schema.ctrls.ivapor1.value, 0, this.schema.ctrls.base1.value],
          [this.schema.ctrls.ivapor2.value, 0, this.schema.ctrls.base2.value],
          [this.schema.ctrls.ivapor3.value, 0, this.schema.ctrls.base3.value],
          [this.schema.ctrls.ivapor4.value, 0, this.schema.ctrls.base4.value],
          [this.schema.ctrls.ivapor5.value, 0, this.schema.ctrls.base5.value]
        ]
      };

      // sumas
      this.psumas = {
        T_bases: this.schema.ctrls.base.value,
        T_ivas: this.schema.ctrls.iva.value,
        T_suplidos: this.schema.ctrls.sup.value
      };

      // totales
      this.ptotales = {
        T_pnf: this.schema.ctrls.pnfsuma.value,
        T_sup_pd: this.schema.ctrls.sup_pd.value,
        T_imp_cia: this.schema.ctrls.imp_cia.value,
        T_importe: this.schema.ctrls.imp.value
      };
    },

    

    

    // devuelvo objeto con los datos de cabeceras y lineas a guardar
    monto_datosylineas(cab, lineas) {
      console.log("-------- Datos MD a guardar : ");

      // monto objeto datos a guardar
      var datosylineas = {
        bd: "fun_gestion",
        id: this.accion === 1 ? 0 : this.id,
        tabla_cabecera: "exp_prescab",
        campos_cabecera: cab ? cab.campos_cabecera.join(",") : null,
        values_cabecera: cab ? cab.values_cabecera.join("]") : null,
        campo_relacionado_cabydetalle: this.schema_detalle.field_relation,
        tabla_lineas: "exp_preslin",
        campos_detalle: lineas ? lineas.campos_detalle.join(",") : null,
        values_detalle: lineas
          ? Object.values(lineas.values_detalle)
              .map(linea => linea.join(":"))
              .join("]")
          : null
      };

      return datosylineas;
    },

    //
    nuevo_fin() {
      //alert("Función Nuevo FIN");
    }
  }
};
</script>
